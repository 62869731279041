/* about.css */

.about-page {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  background-color: #1B4369; /* Softer background color */
  font-family: 'Arial', sans-serif;
}

.about-container {
  max-width: 800px;
  width: 100%; /* Make the container responsive */
  padding: 28px;
  margin: 50px 30px 20px 30px; /* Move the container up by 20px */
  background-color: #487dae;
  border-radius: 8px;
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.283);
  box-sizing: border-box; /* Ensure padding and margin are included in the width */
  transition: transform 0.3s;
}

.fed-watchers {
  color: #f0ea90;
  font-size: 1.4em;
  text-decoration: italic;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 25px;
}

.about-description {
  font-size: 1.2em;
  text-align: left;
  line-height: 1.4;
  color: white;
  margin-top: 0px;
  margin-bottom: 0px;
}

.about-link {
  color: #004085;
  text-decoration: none;
  font-weight: bold;
}

.about-link:hover {
  text-decoration: underline;
  color: #f0ea90;
}
