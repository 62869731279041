html {
  overflow-x: hidden; /* Prevent horizontal overflow */
}

body {
  margin: 0;
  padding: 0;
  background-color: #1B4369;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantaloupe', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: auto;
  will-change: transform;
}

/* In App.css */
/* .main-page-background {
  background-color: #1B4369; 
}

.profile-page-background {
  background-color: white;
} */

body.chrome .app {
  margin-top: 20px; /* Adjust the top margin for Chrome */
}

body.edge .app {
  margin-top: 30px; /* Adjust the top margin for Chrome */
}

body.safari .app {
  margin-top: 20px; /* Adjust the top margin for Chrome */
}

body.safari .timeline-bar {
  margin-bottom: -10px; /* Adjust the height as needed for Safari */
}

.app {
  font-family: 'Arial', sans-serif;
  text-align: center;
}

.app-header {
  color: white;
  font-size: 2rem;
}

.app-logo {
  width: 400px;   /* Set the width to the desired fixed size */
  margin-top: 60px;
  margin-right: 20px;
  margin-left: 20px;
  cursor: pointer; 
  /* You can add more styling as needed */
}

.icon-container {
  display: inline-block;
  text-align: center;
  position: relative;
  bottom: -10px;
}

.methodology-icon,
.sub-icon {
  display: none;
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translateX(-50%);
  height: 18px;
}

.sub-icon {
  height: 20px; /* Make the sub-icon larger */
}

.methodology-icon {
  height: 19px;
}

.icon-container:hover .fed-logo {
  transform: scale(1.1);
  filter: drop-shadow(0 0 0.9px rgba(255, 255, 255, 0.8));
}

.icon-container:hover .methodology-icon,
.icon-container:hover .sub-icon {
  display: block;
}

.tagline {
  margin-top: 30px;
  margin-bottom: 70px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 1.15rem;  /* Larger text for the tagline */
  font-style: italic;
  color: #709dc7;  /* A subtle grey, adjust color as needed */
}

@media (max-width: 680px) {
  .app-logo {
    width: 380px;
    margin-top: 35px;
    margin-left: auto;
    margin-right: auto;
  }
  .tagline {
    font-size: 1.08rem;
    margin-top: 15px;
    margin-bottom: 90px;
  }
  html, body {
    overflow-x: hidden; /* Prevent horizontal overflow */
    overflow-y: auto; /* Allow vertical overflow */
    height: 100%;
  }
}

@media (max-height: 700px) {
  .app-logo {
    margin-top: 20px;
  }
  .tagline {
    margin-top: 10px;
  }
}

.fed-logo {
  height: 50px;
  cursor: pointer;
}

.fed-logo-menu {
  display: none;
  position: absolute;
  left: 32px;
  top: 25px;
  height: 25px;
  z-index: 1000;
}

.fed-logo-menu:hover {
  transform: scale(1.1);
  filter: drop-shadow(0 0 0.9px rgba(255, 255, 255, 0.8));
}

/* Media query to hide the fed-logo on screens smaller than 680px */
@media (max-width: 680px) { /* 680px */
  .fed-logo {
    display: none;
  }
  .fed-logo-menu {
    display: block;
    height: 40px; /* Adjust size as needed */
    cursor: pointer;
  }
}

.menu-icon {
  cursor:pointer;
  width: 30px; /* Adjust size as needed */
  height: auto;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.menu-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align left */
  position: absolute;
  top: 60px; /* Adjust to place below the icon */
  left: 25px; /* Align with menu icon */
  background:#e5eaee;
  padding: 0px;
  z-index: 1000;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); /* Add subtle shadow */
  animation: slide-down 0.3s ease-out; /* Add slide down animation */
}

.menu-content a {
  color: #1B4369;
  padding: 10px 20px; /* Adjust padding for better spacing */
  text-decoration: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-size: 1rem; /* Adjusted font size */
  transition: background 0.3s, color 0.3s; /* Smooth transition effects */
}

.menu-content a:hover {
  background:#1B4369;;
  color: white;
}

/* Base styles for filter bar */
.filter-bar {
  margin-left: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; /* Ensures elements are spaced out */
  margin-top: -50px;
  padding: 20px;
  gap: 20px;
  width: 100%; /* Ensures the bar takes the full width */
}

/* Ensure that the filter groups take up only as much space as needed */
.filter-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

/* Flex container for date filters and the checkbox */
.filter-group-date, .filter-group-checkbox {
  display: flex;
  flex-direction: row;  /* Inline layout for items within the group */
  align-items: center;  /* Aligns items vertically */
  gap: 10px;  /* Space between each filter item or checkbox */
}

/* Ensuring the date filters use available space efficiently */
.filter-group-date {
  flex-grow: 1;  /* Allows the date filter group to expand and fill the space */
}

/* Styling for checkbox to ensure it is positioned to the right */
.filter-group-checkbox {
  flex-shrink: 0;  /* Prevents the checkbox group from shrinking */
  gap: 0px !important;
}

.export-button-medium {
  display: none;
}

/* Specific style for the export button */
.export-button, .export-button-medium {
  margin-top: 47px;
  margin-right: 240px;
  padding: 9px 20px;
  font-size: 1rem;
  color: #ffffff;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 5px; /* Rounded corners for a more modern look */
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s ease, border 0.2s ease, box-shadow 0.2s ease, transform 0.1s ease;;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

/* Hover effect for the export button */
.export-button:hover, .export-button-medium:hover {
  background-color: #487dae; /* Change button color on hover */
  color: white; /* Ensure text color remains white */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.4); /* Enhance shadow on hover */
}

/* Active effect for the export button */
.export-button:active {
  outline: none;

  font-weight: bold;
  transform: scale(1.04); /* Slightly increase the size */
}

.filter-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.5rem;
  color: white;
  min-width: 200px; /* Minimum width for each filter */
  box-sizing: border-box; /* Includes padding in width calculation */
}

.filter-item.export-button-container {
  min-width: auto; /* Override min-width for the export button container */
}

.date-filters .filter-item {
  min-width: auto; /* Remove the minimum width for date filters */
}

/* Uniform styles for select and date input */
.filter-item select, .filter-item input[type="date"] {
  width: 100%; /* Makes the inputs fill their filter-item container */
  padding: 6px;
  height: 40px;
  border: 1px solid #000000;
  border-radius: 8px;
  background-color: white;
  color: #444;
}

/* Specific label styles for all filter-item labels */
.filter-item label {
  margin-top: 10px;
  margin-bottom: 10px;
}

.affiliation-select {
  min-width: 230px;
}

/* Adjust specific styles for date inputs */
.date-input {
  margin-top: 2px !important;
  font-family: Arial;
  height: 26px !important; /* Uniform height with other inputs */
  width: 150px !important;
}

/* Styling for select elements and inputs within filters */
select, input[type="date"] {
  padding: 6px !important;
  border-radius: 5px !important;
  border: 1px solid #ced4da;
}

.checkbox-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 45px;
  margin-left: 0px;
  order: 1;
  color: white;
  cursor: default;
}

.checkbox-item input[type="checkbox"] {
  cursor: pointer; /* Set cursor to pointer for checkboxes */
}

.checkbox-input {
  margin-left: 15px; /* Optionally add margin to the left of the checkbox */
}

/* Container for the date filters and checkbox */
.date-checkbox-group {
  display: flex;
  align-items: center; /* Align items vertically */
  justify-content: start; /* Space between date filters and checkbox */
  width: 100%; /* Take the full width to utilize the space */
  margin-right: 0px; /* Adjust as necessary to align right */
}

/* Styles for date filters container */
.date-filters {
  display: flex;
  margin-left: 10px;
  margin-right: 30px;
  margin-top: 5px;
  justify-content: center; /* Center the date filters */
  align-items: center; /* Center the date filters vertically */
  gap: 10px; /* Space between start and end date inputs */
}

.date-label {
  font-size: 18px;
}


@media (max-width: 1500px) {
  .filter-bar {
    flex-wrap: wrap; /* Allow wrapping */
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    margin-left: -20px;
  }
  .filter-group, .date-checkbox-group {
    justify-content: center; /* Center the groups */
    align-items: center; /* Center the groups vertically */
    width: 100%; /* Ensure they take up full width */
  }
  .date-filters {
    margin-top: -10px;
    margin-left: -2px;
    margin-right: 20px;
    justify-content: center; /* Center the date filters */
    align-items: center; /* Center the date filters vertically */
  }
  .checkbox-item {
    margin-top: 30px;
    margin-right: 20px;
  }
  .export-button {
    display: none;
  }
  .export-button-medium {
    display: flex;
    margin-top: 33px;
    margin-left: 18px;
    margin-right: 0px;
    order: 2;
  }
}

/* Media query for screens less than 600px */
@media (max-width: 680px) {
  .filter-bar {
    flex-direction: column;  /* Stack children vertically */
    align-items: flex-start;  /* Left align the children */
    justify-content: center;  /* Center vertically in case there's extra space */
    margin-top: -75px;
    margin-left: 50px;
    gap: 10px;  /* Reduce gap for smaller screens */
    width: 300px;  /* Use full width for better layout control */
  }

  .filter-item {
    width: 230px;  /* Full width for a better mobile experience */
  }

  .filter-group, .date-checkbox-group {
    flex-direction: column;  /* Ensure all filter groups also stack vertically */
    align-items: flex-start;  /* Keep items left aligned within each group */
    width: 300px;  /* Full width for better touch targets and appearance */
    padding-left: 0;
  }

  .export-button-medium {
    display: none;
  }

  .export-button {
    display: flex;
    margin-left: 1px;
    justify-content: center;
    order: 1;  /* Ensures the export button is at the bottom */
    width: 227px;  /* Full width for a better mobile experience */
    margin-top: 20px;  /* Add more space above the export button */
  }

  .date-input {
    width: 215px !important;  /* Full width for a better mobile experience */
  }

  .date-filters {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 10px;
    margin-left: 0px;
    margin-bottom: -10px;
    gap: 10px;
    width: 300px;  /* Full width for a better mobile experience */
  }

  .checkbox-item {
    margin-top: 40px;
    width: 338px;  /* Full width to use the available space */
    order: 1;  /* Ensure no margin is pushing the checkbox right */
  }
}


.timeline-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  width: 100%; /* Subtract the total padding from the width */
}

/* Safari-specific adjustments */
body.safari .timeline-wrapper {
  margin-left: -18px; /* Adjust this value as needed to align properly */
  width: calc(100% + 18px);
}

/* Pseudo-elements for the fading effect */
.timeline-wrapper::before,
.timeline-wrapper::after {
  content: '';
  position: absolute;
  margin-top: -50px;
  margin-bottom: 80px;
  top: 0;
  bottom: 0;
  width: 60px; /* Width of the fading effect */
  z-index: 11;
  pointer-events: none; /* Allow clicks to pass through */
}

.timeline-wrapper::before {
  left: 0;
  background: linear-gradient(to right, rgba(27, 68, 105, 1) 0%, transparent 50%);
}

.timeline-wrapper::after {
  right: 0;
  background: linear-gradient(to left, rgba(27, 68, 105, 1) 0%, transparent 50%);
}

/* Safari-specific adjustments */
body.safari .timeline-wrapper::before {
  left: 18px;
  background: linear-gradient(to right, rgba(27, 67, 105, 1) 0%, rgba(27, 67, 105, 0) 50%);
}

body.safari .timeline-wrapper::after {
  right: -2px;
  background: linear-gradient(to left, rgba(27, 67, 105, 1) 0%, rgba(27, 67, 105, 0) 50%);
}

.scroll-arrow {
  position: fixed;
  color: white;
  top: 30%;
  padding: 20px; /* Increase padding for larger clickable area */
  font-size: 30px; /* Increase font size for larger arrow */
  transform: translateY(-50%);
  z-index: 10000; /* Ensure they are above other elements */
  background: #1B4369; /* Adjust background and styling as needed */
  border: none;
  padding: 3px;
  cursor: pointer;
  opacity: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-arrow:hover{
  opacity: 90%;
}

.scroll-arrow svg {
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
}

.scroll-arrow.left-arrow {
  position: absolute;
  left: 0px; /* Adjust distance from left edge */
}

.scroll-arrow.right-arrow {
  position: absolute;
  right: 0px; /* Adjust distance from right edge */
}

.scroll-arrow.disabled {
  display: none;
}

body.safari .scroll-arrow.left-arrow {
  left: 15px;
}

body.safari .scroll-arrow.right-arrow {
  right: -5px;
}


.timeline-container {
  overflow-x: scroll; /* Always show horizontal scrollbar, was auto */
  overflow-y: hidden;
  position: relative; /* Position for child absolute elements */
  width: 100%;
  white-space: nowrap; /* Prevents wrapping of timeline events */
  height: 790px; /* Adjust the height to fit your design */
  cursor: grab;
  scroll-behavior: smooth;
  padding-bottom: 40px; /* Add padding to create space for the scrollbar */
  margin-bottom: -40px; /* Shift the scrollbar into the created space */
}

/* Custom scrollbar styles for WebKit browsers (Chrome, Safari) */
.timeline-container::-webkit-scrollbar {
  height: 12px;
}

.timeline-container::-webkit-scrollbar-thumb {
  background-color: #487dae; /* Scrollbar thumb color */
  border-radius: 10px;
  border: 3px solid #1B4369; /* Thumb border to give padding effect */
}

.timeline-container::-webkit-scrollbar-track {
  background: #1B4369; /* Scrollbar track color */
  border-radius: 10px;
}

/* Custom scrollbar styles for Firefox */
.timeline-container {
  scrollbar-width: medium; /* Makes scrollbar smaller */
  scrollbar-color: #487dae #1B4369; /* Thumb color and track color */
}

/* Media query for mobile devices */
@media (max-width: 680px) {
  .timeline-wrapper {
    top: 0px;
    height: 900px;
    margin-top: 20px;  /* Reduce the top margin to move it higher */
  }
  .timeline-bar {
    bottom: 155px !important;
  }
  .scroll-arrow {
    top: 34%;
  }
  .timeline-wrapper::before {
    margin-top: -310px;
  }
  .timeline-wrapper::after {
    margin-top: -310px;
  }
  /* Safari-specific adjustments */
  body.safari .timeline-bar {
    bottom: 150px !important;
  }
}

@media (max-width: 1500px) and (min-width: 681px) {
  .timeline-wrapper {
    margin-top: 40px;  /* Reduce the top margin to move it higher */
  }
  .timeline-bar {
    bottom: 185px !important;
  }
}

.timeline-container.grabbing {
  cursor: grabbing; /* When dragging, show the 'grabbing' cursor */
}

.timeline-bar {
  position: absolute;
  min-width: 100%;
  /* height: calc(100% - 0); */
  bottom: 210px;
  display: flex; /* Align dots in a row */
  align-items: center; /* Center dots vertically */
}

/* New styles for the timeline line */
.timeline-line {
  position: absolute;
  height: 3px; /* Thickness of the line */
  background-color: #fff; /* Color of the line */
  top: 87%; /* Center the line vertically */
  transform: translateY(100%); /* Ensure it's centered regardless of height */
  opacity: 100%;
}

.timeline-line:hover {
  opacity: 100%;
}

.timeline-dot {
  width: 10px; /* Size of the dot */
  height: 10px; /* Size of the dot */
  top: 87%;
  border-radius: 50%; /* Make the dot circular */
  background-color: #fff; /* Dot color */
  position: absolute; /* Positioning relative to the timeline bar */
  cursor: pointer; /* Change the cursor to signify it can be interacted with */
  box-shadow: 0 0 0 2px #1B4369; /* Add a border-like effect with box-shadow */  
}

.timeline-dot.selected {
  background-color: #61a1dc; /* Color when selected */
  border: 1px solid #f0ea90;
}

.timeline-dot.unselected {
  background-color: #fff; /* Reset to default color */
  border: none; /* Remove border */
}

.timeline-dot:hover {
  background-color: #61a1dc; /* Change dot color on hover */
  border: 1px solid #f0ea90;
}

.blackout-icon-wrapper {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 86%;
  transform: translateY(-30%);
  z-index: 3;
  cursor: pointer;
}

.blackout-icon-background {
  width: 100%;
  height: 100%;
}

.blackout-icon-foreground {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: filter 0.1s, box-shadow 0.3s;
  cursor: pointer;
}

.blackout-icon-foreground:hover {
  filter: brightness(1.5);
}

.timeline-label {
  position: absolute;
  top: 89% !important; /* Position at the bottom of the timeline line */
  white-space: nowrap; /* Keep label text on one line */
  padding: 35px 0px; /* Small padding around text */
  font-size: 1rem; /* Smaller font size for neat appearance */
  color: #ffffff; /* Text color for visibility */
}

.modal-custom {
  font-family: 'Arial', sans-serif;
  background-color: #487dae;
  border-radius: 8px;
  border: 1px solid #f0ea90;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  padding: 0px;
  padding-bottom: 25px;
  position: relative;
  bottom: 28%;
  z-index: 100;
  width: 300px; /* Set a fixed width or as per requirement */
  max-height: 590px;
  display: flex;
  flex-direction: column; /* Stack header and content */
  overflow: visible;
  pointer-events: auto;
  /* transition: transform 0.1s ease, left 0.1s ease; */
}

.modal-header-custom {
  background-color: #004085; /* A dark blue background */
  color: white;
  font-weight: bold;
  padding: 8px 15px;
  border-top-left-radius: 8px; /* Rounded corners for the top */
  border-top-right-radius: 8px;
  position: sticky; /* Make the header fixed */
  width: 90%;
}

.event-count {
  color: #6492bd;
  font-size: 14px;
  cursor: pointer;
}

.modal-container {
  pointer-events: none;
}

.modal-content-wrapper {
  pointer-events: auto;
}

.modal-content-container {
  max-height: 560px; /* Inherit the maximum height from the parent modal */
  overflow-y: auto; /* Enable vertical scrolling when content exceeds the maximum height */
  position: relative;
  margin-bottom: -5px;
}

@media (max-height: 700px) and (max-width: 680px) {
  .timeline-wrapper {
    height: 740px;
    margin-top: 40px;
  }
  .timeline-bar {
    bottom: 220px !important;
  }
  /* Safari-specific adjustments */
  body.safari .timeline-bar {
    bottom: 220px !important;
  }
  .modal-custom {
    max-height: 500px; /* Inherit the maximum height from the parent modal */
  }
  .modal-content-container {
    max-height: 470px; /* Inherit the maximum height from the parent modal */
  }
  .scroll-arrow {
    top: 38.5%;
  }
  .footer {
    margin-top: -40px !important;
  }
}

@media (max-height: 700px) and (min-width: 681px) {
  .timeline-wrapper {
    margin-top: 0px;
    height: 730px;
  }
  .timeline-bar {
    margin-bottom: 0px;
  }
  .timeline-wrapper::before,
  .timeline-wrapper::after {
    margin-top: 0px;
    margin-bottom: 80px;
  }
  .modal-custom {
    max-height: 470px; /* Inherit the maximum height from the parent modal */
  }
  .modal-content-container {
    max-height: 440px; /* Inherit the maximum height from the parent modal */
  }
  .scroll-arrow {
    top: 41%;
  }
}

@media (min-height: 701px) and (min-width: 681px) {
  .timeline-bar {
    bottom: 210px !important;
  }
}

@media (max-height: 700px) and (min-width: 681px) {
  .timeline-bar {
    bottom: 185px !important;
  }
}

.modal-content-container::before,
.modal-content-container::after {
  content: '';
  position: fixed;
  margin-top: 34px;
  margin-bottom: 18px;
  left: 0;
  right: 0;
  height: 16px; /* Height of the fading effect */
  pointer-events: none; /* Ensures clicks pass through to elements below */
  z-index: 2; /* Ensure it's above the scrolled content */
}

.modal-content-container::before {
  top: 0;
  background: linear-gradient(to bottom, #487dae 0%, transparent 100%);
}

.modal-content-container::after {
  bottom: 0;
  background: linear-gradient(to top, #487dae 0%, transparent 100%);
}

/* Safari-specific adjustments */
body.safari .modal-content-container::before,
body.safari .modal-content-container::after {
  content: '';
  position: fixed;
  margin-top: 33px;
  margin-bottom: 19px;
  left: 0;
  right: 0;
  height: 16px;
  pointer-events: none;
  z-index: 2;
}

body.safari .modal-content-container::before {
  background: linear-gradient(to bottom, #487dae 0%, rgba(72, 125, 174, 0) 50%);
  top: 0;
}

body.safari .modal-content-container::after {
  background: linear-gradient(to top, #487dae 0%, rgba(72, 125, 174, 0) 50%);
  bottom: 0;
}

.modal-content {
  display: flex;
  align-items: center;
  padding: 10px;
  padding-bottom: 0px;

}

.modal-image-container {
  align-self: flex-start;
  display: flex;
  flex-direction: vertical;
  justify-content: left; /* Center image */
  margin-left: 2px;
}

.modal-image {
  width: 45px; /* Set image size */
  height: 60px;
  display: flex;
  cursor: pointer;
}

.modal-body-custom {
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-left: 5px;
  padding-top: 0px;
  background-color: #487dae; /* Slightly grey background for the body */
  border-radius: 8px;
  color: white;
  font-size: 0.9rem; /* Adjust the font size as needed */
  text-align: left;
}

.modal-name, .modal-title,
.modal-remark {
  text-align: left; /* Align text to the left */
  margin-left: 10px; /* Provide some space from the left edge */
}

/* Additional styles to make sure the title is not centered if it's inheriting styles from somewhere */
.modal-name {
  color: #ece148;
  font-weight: bold;
}

.modal-name a {
  text-decoration: none;
  color: inherit;
}

.modal-name a:hover {
  text-decoration: underline;
}

.tooltip .asterisk {
  cursor: pointer;
  text-decoration-style: dotted;
}

.tooltip[title]:hover:after {
  content: attr(title);
  position: absolute;
  left: 100%;
  top: 0;
  white-space: nowrap;
  z-index: 9999;
  background-color: #f9f9f9;
  color: #000;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 3px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
  opacity: 0;
  transition: opacity 0.5s;
}

.tooltip[title]:hover:after {
  opacity: 1;
}

/* Additional styles to make sure the title is not centered if it's inheriting styles from somewhere */
.modal-title {
  margin-top: 5px;
  color: #f0ea90;
  font-style: italic;
}

/* Make sure remarks follow the same text alignment */
.modal-remark {
  color: white;
  margin-top: 10px;
}

.modal-pointer {
  width: 20px;
  height: 20px;
  background-color: #487dae;
  border: 1px solid #f0ea90;
  position: absolute;
  bottom: -10px; /* Half of the height to pull it outside the box */
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  z-index: 101;
}

.modal-pointer::after {
  content: '';
  width: 50px;
  height: 20px;
  background-color: #487dae;
  position: absolute;
  bottom: 13px; /* Position it slightly above the bordered pointer */
  left: 50%;
  transform: translateX(-50%) rotate(-45deg);
  z-index: 102; /* Higher z-index for the pointer without the border to be on top */
}

/* Close button styling */
.btn-close-custom {
  background: none; /* No background */
  border: none; /* No border */
  color: #ffffff; /* White text color */
  font-size: 1.2rem; /* Larger font size for visibility */
  font-weight: bold;
  opacity: 0.6; /* Slightly transparent */
  cursor: pointer; /* Pointer cursor on hover */
  line-height: 1; /* Adjust line height to control the height of the button */
  padding: 0; /* Reset padding */
  position: absolute;
  right: 15px; /* Position inside the top-right of the header */
  top: 8px;
  pointer-events: auto; 
}

.btn-close-custom:hover {
  opacity: 0.85;
}


/* Default hidden state for slider controls */
.slider-controls, .slider-controls-small {
  display: none;
  align-items: center;
  justify-content: center; /* Center the slider and label horizontally */
  bottom: 90px;
  transform: translateX(12%);
  width: auto;
  position: relative;
}

/* Display large slider controls for screens larger than 600px */
@media (min-width: 681px) {
  .slider-controls {
    display: inline-flex;
  }
  .slider-controls-small {
    display: none;
  }
}

/* Display small slider controls for screens 600px and smaller */
@media (max-width: 680px) {
  .slider-controls {
    display: none;
  }
  .slider-controls-small {
    display: inline-flex;
    top: 15px;
    left: -33px;
  }
}

.zoom-slider, .zoom-slider-small {
  -webkit-appearance: none;
  flex-grow: 0; /* Allow the slider to take up available space */
  max-width: 12%; /* Maximum width for the slider */
  min-width: 200px; /* Minimum width for the slider */
  height: 5px;
  border-radius: 2px;
  background: white;
  outline: none;
  opacity: 0.6;
  transition: opacity 0.2s;
}

.zoom-slider:hover, .zoom-slider-small:hover {
  opacity: 1;
}

/* Chrome, Safari, and newer Edge versions */
.zoom-slider::-webkit-slider-thumb, .zoom-slider-small::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #487dae;
  cursor: pointer;
  border: 1.5px solid white;
}

.zoom-slider::-moz-range-thumb, .zoom-slider-small::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1.5px solid white;
  background: #487dae;
  cursor: pointer;
}

.zoom-factor, .zoom-factor-small {
  margin-top: 8px;
  margin-left: 5px; /* Space between slider and label */
  font-size: 14px;
  color: #ffffff;
  background-color: #1B4369;
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0.7;
  text-align: center;
  white-space: nowrap;
  flex-shrink: 0; /* Prevent the label from shrinking */
}

.remark-link {
  color: inherit; /* Inherits the text color from its parent */
  text-decoration: none; /* Removes underline */
  cursor: pointer; /* Shows pointer cursor on hover */
}

.remark-link:hover {
  text-decoration: underline; /* Optional: underline on hover for clarity */
}


.methodology-note {
  margin-top: 50px;
  text-align: left;
  background-color: #487dae;
  color: white;
  width: 100%;
  border-top: 1.5px solid #f0ea90;
}

.methodology-note.show {
  display: block; /* Show when class 'show' is added */
}

.methodology-header {
  background-color: #487dae; /* Different background color for header */
  position: relative;
  padding: 30px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.methodology-header h2 {
  margin: 0;
  position: absolute;
  left: 50%;
  color: #ece148;
  transform: translateX(-50%);
}

.methodology-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px; /* Optional: add some padding on the sides */
  box-sizing: border-box;
}

.methodology-content {
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #487dae; /* Background color for content */
}

.methodology-content h3 {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #f0ea90;
}

.methodology-content p,
.methodology-content ul {
  margin: 10px 0;
}

.methodology-content ul {
  padding-left: 20px;
}

.methodology-content ul li {
  margin-bottom: 5px;
}

.methodology-asterisk {
  font-size: 0.9em;
  text-decoration: italic;
  padding-top: 5px;
}

.hide-link-button {
  background: none;
  border: none;
  color: #ece148;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  margin-right: 20px; /* Add space between the hide link and the title */
}

.hide-link-button:hover {
  color: #d4c514; /* Change color on hover */
}

@media (max-width: 768px) {
  .methodology-container {
    padding: 0 10px; /* Adjust padding for smaller screens */
  }
}

@media (max-width: 680px) {
  .methodology-header h2 {
    font-size: 1.4rem;
  }
}

@media (max-width: 400px) {
  .methodology-header h2 {
    font-size: 1.2rem;
  }
  .hide-link-button {
    font-size: 12px;
  }
  .methodology-content h3 {
    font-size: 17px;
  }
}

.footer {
  display: flex;
  position: relative;
  justify-content: center;
  color: #6d97be;
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 10px;
  pointer-events: none;
}

@media (max-width: 680px) {
  .footer {
    margin-top: -60px;
  }
}

/* Subscribe modal properties */

.subscribe-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.subscribe-modal-content {
  text-align: center; 
  background-color: #1B4369;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.subscribe-modal-content h2 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 1.5rem;
  color: #ece148;
  font-style: italic;
  text-align: center; /* Center the text */
  width: 100%; /* Ensure the heading takes the full width */
}

.subscribe-modal-content p {
  margin-bottom: 10px;
  font-size: 1rem;
  color: #ffffff;
  text-align: left;
  line-height: 1.3;
  margin-left: 5px;
}

.subscribe-form .form-group {
  display: flex;
  align-items: center;
  text-align: center;
  gap: 20px; /* Add space between the input and the button */
  width: 100%; /* Make sure the form group takes the full width */
}

.subscribe-modal-content input[type="email"] {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
}

.subscribe-modal-content button {
  background-color: #1B4369;
  margin-bottom: 10px;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Add subtle shadow for depth */
}

.subscribe-modal-content button:hover {
  background-color: #487dae;
}

.subscribe-modal-content button:active {
  transform: scale(0.98); /* Slightly shrink button when clicked */
}


.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: rgb(175, 175, 175);
  text-decoration: none;
  cursor: pointer;
}

.subscribe-modal-content form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.subscribe-modal-content label {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 1rem;
}

.subscribe-modal-content input[type="email"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 0px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.subscribe-modal-content button {
  padding: 10px 20px;
  margin-bottom: 0;
  background-color: #1B4369;
  color: white;
  font-size: 1rem;
  border: 1px solid white;
  border-radius: 5px; /* Rounded corners for a more modern look */
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s ease, border 0.2s ease, box-shadow 0.2s ease, transform 0.1s ease;;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.subscribe-modal-content button:hover {
  background-color: #487dae;
}

.fdb-screenshot {
  width: 100%;
  margin-top: 5px;
  border: 2px solid #3b3e43;
  border-radius: 5px; /* Rounded corners for a more modern look */
  box-shadow: 0 4px 8px #393c41;
}

.success-modal {
  position: fixed;
  z-index: 1001;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #1B4369;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 400px;
  max-width: 80%;
  text-align: center;
}

.hidden {
  display: none;
}

.success-modal-content {
  position: relative;
  padding: 10px;
  font-size: 18px;
  border-radius: 10px;
}

.success-modal .close {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 20px;
  cursor: pointer;
  color: #ffffff;
}

.success-modal h2 {
  margin: 0 0 0px 0;
  color: #ffffff;
}

.success-modal p {
  color: #ffffff;
}



/* Nav bar styling */
.nav-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 40px 40px;
  background-color: transparent; /* Same background color as the header */
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.nav-icon1 {
  width: 52px; /* Adjust the size as needed */
  height: 20px;
  margin-right: 8px;
  opacity: 0.9;
}

.nav-icon2 {
  width: 56px; /* Adjust the size as needed */
  height: 20px;
  margin-right: 8px;
  opacity: 0.9;
}

.nav-icon3 {
  width: 80px; /* Adjust the size as needed */
  height: 20px;
  margin-right: 8px;
  opacity: 0.9;
}

.nav-icon4 {
  width: 65px; /* Adjust the size as needed */
  height: 20px;
  margin-right: 8px;
  opacity: 0.9;
}

.nav-icon1:hover,
.nav-icon2:hover,
.nav-icon3:hover,
.nav-icon4:hover {
  transform: scale(1.1); /* Lighter color on hover */
  opacity: 1;
}

.nav-links {
  display: flex;
  gap: 30px;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
  position: relative; /* Ensure nav-link is positioned relative */
}

.dropdown-menu {
  margin-top: 10px;
  display: block;
  position: absolute;
  top: 100%; /* Position below the nav-link */
  left: 0; /* Align to the left of the nav-link */
  background-color: #e5eaee;
  width: 200px; /* Match the width of the hamburger content */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); /* Add subtle shadow */
  animation: slide-down 0.3s ease-out; /* Add slide down animation */
  z-index: 1000;
}

.dropdown-item {
  color: #1B4369;
  text-align: left;
  padding: 10px 20px;
  text-decoration: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-size: 1rem; /* Adjusted font size */
  transition: background 0.3s, color 0.3s; /* Smooth transition effects */
}

.dropdown-item:hover {
  background: #1B4369;
  color: white;
}

.hamburger-menu {
  margin-top: -15px;
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  cursor: pointer;
  opacity: 0.8;
}

.menu-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align left */
  position: absolute;
  top: 60px; /* Adjust to place below the icon */
  left: 25px; /* Align with menu icon */
  background:#e5eaee;
  padding: 0px;
  z-index: 1000;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); /* Add subtle shadow */
  animation: slide-down 0.3s ease-out; /* Add slide down animation */
}

.hamburger-menu:hover {
  opacity: 1;
}

.hamburger-menu .bar {
  width: 100%;
  height: 2px;
  background-color: white; /* Default color */
  transition: background-color 0.3s;
}

.hamburger-menu-profile .bar {
  height: 2.5px;
  background-color: #1B4369; /* Color for profile page */
}


.hamburger-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  position: absolute;
  top: 60px;
  right: 20px;
  background: #e5eaee;
  padding: 0;
  z-index: 1000;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  animation: slide-down 0.3s ease-out;
}

.hamburger-content a,
.hamburger-content .dropdown-link {
  color: #1B4369;
  padding: 10px 20px;
  text-decoration: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-size: 1rem;
  transition: background 0.3s, color 0.3s;
}

.hamburger-content a:hover,
.hamburger-content .dropdown-link:hover {
  background: #1B4369;
  color: white;
}

.hamburger-content .dropdown-link {
  position: relative;
}

.hamburger-content .people-dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 100%;
  width: 200px;
  background-color: #cbd2dd;
  border: 1px solid #ccc;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  z-index: 10000;
}

.hamburger-content .dropdown-item {
  display: block;
  padding: 10px 20px;
  white-space: nowrap;
  text-decoration: none;
  color: #1B4369;
}

.hamburger-content .dropdown-item:hover {
  background-color: #1B4369;
}

@media (max-width: 1480px) and (max-height: 700px), 
       (max-width: 1420px) and (min-height: 701px) {
  .nav-links {
    display: none;
  }
  .hamburger-menu {
    display: flex;
  }
  .hamburger-content {
    width: 120px; /* Ensure this matches the width of the menu-content */
    right: 20px; /* Align the dropdown to the right side of the screen */
  }
}