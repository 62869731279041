/* ProfilePage.css */

.profile-page {
  padding: 20px 20px 20px; /* Adjust padding-top to accommodate the header height */
  background-color: white;
  min-height: 100vh;  /* Make sure it covers the full viewport height */
  box-sizing: border-box;  /* Include padding in height calculation */
  margin-top: 0px;
}

body.chrome .profile-page {
  margin-top: -20px;
}

body.safari .profile-page {
  margin-top: -20px;
}

body.edge .profile-page {
  margin-top: -30px;
}

/* Add any other styles you need for the profile page */
.profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.official-image,
.fomc-image {
  display: none; /* Initially hide the image */
  margin: 0;
}

.official-image {
  width: 130px;
  height: 170px;
}

.fomc-image {
  width: 130px;
  height: 90px;
}

.official-image-loaded {
  display: inline-block; /* Show the image once loaded */
}

.profile-name {
  margin-top: 15px; /* Space between image and name */
  font-size: 24px; /* Adjust the font size */
  font-weight: bold; /* Make the name bold */
  text-align: center; /* Center the text */
  color: #333; /* Adjust the color if needed */
}

.profile-role p {
  margin:0;
  margin-top: -5px;
  font-size: 1.1em;
  color: #555;
}

.role {
  line-height: 1.1em;
  color: #555;
  font-style: italic;
}

.term {
  padding-top: 12px;
  color: #888;
  font-size: 1.1em !important;
}

.profile-page h1 {
  margin-bottom: 20px;
}

.profile-page button {
  margin-bottom: 20px;
}

/* New table container for horizontal scrolling */
.table-container {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px 12px;
  text-align: left;
  border: 1px solid #ddd;
}

td.source-column {
  min-width: 90px; /* Set a minimum width for the Source column */
}

td.date-column {
  min-width: 82px; /* Set a minimum width for the Source column */
}

@media (max-width: 680px) {
  td.source-column {
    min-width: 50px; /* Set a minimum width for the Source column */
  }
}

th {
  background-color: #f4f4f4;
  position: sticky;
  top: 0;
}

th select {
  width: 100%;
  margin-top: 5px;
}

th input {
  width: 100%;
  margin-top: 5px;
}

th span {
  margin-left: 5px;
}

td a {
  color: #1a4369;
  text-decoration: none;
}

td a:hover {
  text-decoration: underline;
}

.accordion-section {
  margin-top: 20px;

}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  color: white;
  background-color: #1a4369;
  border: 1px solid #487dae;
}

.accordion-header h2 {
  margin: 10px;
  margin-left: 5px;
  font-size: 1.3em;
}

.accordion-header .icon {
  font-size: 1.5em; /* Increase font size */
}

.accordion-content {
  padding: 10px;
}

.accordion-content h3 {
  text-align: left;  /* Left-align the section headers */
  margin-left: 5px;
  font-size: 1em;
}

.accordion-content table {
  width: auto;  /* Set the table width to auto */
  margin-left: 5px;
  border-collapse: collapse;
}

.accordion-content th, .accordion-content td {
  text-align: left;  /* Left-align the table text */
  padding: 8px 12px;
  border: 1px solid #ddd;
}

.accordion-content th {
  background-color: #f4f4f4;
}

.export-button-profile-container {
  display: flex;
  justify-content: flex-start; /* Align the button to the right */
  margin-top: 5px;
  margin-bottom: -3px;
  margin-left: 5px;
}

.export-button-profile {
  font-size: 1rem;
  color: #1a4369;
  padding: 7px 18px;
  background-color: transparent;
  border: 1px solid #487dae;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}

.export-button-profile:hover {
  border-radius: 5px;
  background-color: #1a4369;
  color: white;
}

.sources-list {
  margin-left: 5px;
  text-align: left;
}

.sources-list a {
  text-decoration: none;
  color: #1a4369;
}

.sources-list a:hover {
  text-decoration: underline;
}

.skeleton {
  background-color: #e0e0e0;
  width: 130px;
  height: 170px;
  display: inline-block;
  border-radius: 4px;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}