/* Analysis.css */
.analysis-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px); /* Adjust based on the height of your navigation bar */
  width: 100%;
  text-align: center;
  background-color: transparent;
  padding-top: 60px; /* Adjust based on the height of your navigation bar */
}

.analysis-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  position: relative; /* Ensure the container is positioned relative */
}

.coming-soon-image {
  object-fit: contain;
  width: 80%;
  max-width: 600px;
  min-width: 300px;
  min-height: 30vh;
  max-height: 50vh; /* Set maximum height for the image */
  margin-top: 20px; /* Move the image up */
}