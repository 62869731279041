.unsubscribe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
  background-color: transparent;
}

.unsubscribe-content {
  color: white;
  padding: 40px;
  padding-top: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #487dae;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.unsubscribe-header {
  color: #f0ea90; /* Change the color to your desired color */
}

.unsubscribe-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.unsubscribe-form label {
  width: 100%;
  text-align: left;
  margin-bottom: 8px;
  font-weight: bold;
}

.unsubscribe-form input {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
}

.unsubscribe-form button {
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 1rem;
  border: 1px solid #ccc;
  background-color: #1B4369;
  color: #fff;
  cursor: pointer;
  width: 100%;
}

.unsubscribe-form button:hover {
  background-color: #13324f;
}

.unsubscribe-message {
  margin-top: 20px;
  margin-bottom: -10px;
  font-size: 14px;
  color: white;
}